$headerBackground: $primaryColorTint3;
$headerBackgroundOpaque: rgba($headerBackground,0.9);
$headerBackgroundTransparent: rgba($headerBackground,0.25);

$navbarBackgroundColor: $primaryColorShade2;
$navbarBorderColor: $primaryColorTint3;
$navbarForegroundColor: $navbarBorderColor;

$navbarHoverBackgroundColor: $primaryColorShade3;
$navbarHoverForegroundColor: $navbarForegroundColor;

$flavorTextForegroundColor: $primaryColorShade1;

#header a img {
  padding: 0
}

#header-wrapper {
  height: $headerHeight;
  background: $headerBackground url(theme/fuckton-of-scripts.gif);
  background-size: 1500px 300px;
  background-position: center top;
  background-attachment: fixed;
  -moz-transition: background-position 10ms ease;
  -webkit-transition: background-position 10ms ease;
}

header {
  height: 100%;
  text-align: center;
  background: -moz-linear-gradient(top,  $headerBackgroundOpaque 0%, $headerBackgroundOpaque 15%, $headerBackgroundTransparent 90%, $headerBackgroundTransparent 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $headerBackgroundOpaque 0%,$headerBackgroundOpaque 15%,$headerBackgroundTransparent 90%,$headerBackgroundTransparent 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $headerBackgroundOpaque 0%,$headerBackgroundOpaque 15%,$headerBackgroundTransparent 90%,$headerBackgroundTransparent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ie-hex-str($headerBackgroundOpaque)', endColorstr='#ie-hex-str($headerBackgroundTransparent)',GradientType=0 ); /* IE6-9 */

  a {
    text-decoration: none;
  }
}

header .logo {
  width: $totalWidth;
  max-width: 100%;
  margin: 0 auto;
}

header #flavor-intro {
  display: block;
  text-align: left;
  width: $totalWidth;
  max-width: 100%;
  height: $headerHeight;
  line-height: 40px;
  color: $flavorTextForegroundColor;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  word-spacing: 2px;
  white-space:nowrap;
  background-image: url("theme/ter_fullbanner4@2x.png");
  //background-size: 790px 134px;
  background-size: contain;
  background-position: left top;
  padding-left: 10px;
  background-repeat:no-repeat;
}

#nav-header {
  display: none;
}


@media print {
  #header-wrapper{
    height:auto!important
  }

  header .logo {
    display: none !important;
  }

  header{
    background-color:#fff;
    height:auto !important;
    display: none;
  }
}

@media screen and (max-width: $totalWidth) {
  #header-wrapper {
    height: 48px;
  }

  header #flavor-intro {
    display: none;
  }

  header .logo {
    background-color: $navbarBackgroundColor;

    #logo-spacer {
      height: 48px;
      background: url(/assets/theme/small-logo6.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: inline-block;
      width: 300px;
      margin: 0 auto;
    }
  }
}