$discussionBackgroundColor: $primaryColorShade3;
$discussionForegroundColor: $primaryColorTint4;

#discussion-wrapper {
  clear: both;
  background-color: $discussionBackgroundColor;
  color: $discussionForegroundColor;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow:    0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow:         0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  padding: 1em 0;
  padding-bottom: 30px;

  #discussion {
    width: $totalWidth;
    margin: 0 auto;
    max-width: 100%;

    h4 {
      font-family: $headerFont;
      text-align: left;
      font-size: 1.6em;
      line-height: 1.6em;
      margin: .3em 0;
      font-weight: bold;
    }

    .st-text, .st-count {
      color: $discussionForegroundColor;
    }

  }

  a, a.grv_article_title {
    color: mix($discussionForegroundColor, $discussionBackgroundColor, 75%) !important;
  }
}

#disqus_thread p {
  font-family: $bodyFont !important
}


@media print {
  #discussion-wrapper, #discussion {
    display: none !important;
  }
}