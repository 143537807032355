#sidebar {
  float: left;
  width: $sidebarWidth; /*Width of left column in pixel*/
  padding-bottom: $buffer;

  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

#sidebar {

  .widget {
    margin: $buffer/2 0 0 0;

    &::after {
      clear:both;
      display: table;
    }
  }

  .widget:first-child {
    margin-top: 0em;
  }

  .widget.widget_text > div.textwidget:only-child {
    margin-top: 30px;
  }

  .widget.widget_good > div.goodwidget:only-child {
    margin-top: 30px;
  }

  li h2 {
    font-family: $headerFont;
    font-size: 1.1em;
    font-weight: bold;
    padding: 5px 0px;
    margin: 0px;
    display:flex;
    line-height: 1.1em;
    color: $navElementColor;
    text-align: left;
    font-size: 1.3em;
    line-height: 1.3em;

    &.widgettitle {
      width: 100%;
      margin-bottom: $buffer/2;
    }

    &.widgettitle::after {
      flex-grow: 100;
      content: "";
      transform: translateY(50%);
      margin-left: 10px;
      border-top: 3px solid $navElementColor;
    }
  }

  .widget-author-bio {

    .name {
      font-weight: normal;
    }

    .author_bio {
      margin: 0 0 10px 0;
      min-height: 0;

      .name {
        border-bottom: 0;
      }

      .avatar {
        border-bottom: 0;
      }
    }
  }

  .widget_good {
    border-top: 3px solid $navElementColor;
    margin-top: $buffer;
  }

  .widget.widget-author-bio {
    border-top: 0;
    margin-top: 0;
  }

  div.author-bio-widget div {
    clear:none;
  }

  li.widget > div:only-child, li.widget h2 {
    // -webkit-box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.15);
    // -moz-box-shadow:    0px -3px 3px 0px rgba(0, 0, 0, 0.15);
    // box-shadow:         0px -3px 3px 0px rgba(0, 0, 0, 0.15);
  }

  li:first-child h2 {
    border-top: 0px;
  }

  ul {
    list-type: none;
    margin: 0;
    line-height: 1.2em;
  }

  li {
    padding-bottom: 0px;
    font-size: 1em;
    text-align: left;
  }

  ul li {
    display: block;
    margin-right: 3px;
  }

  li ul li {
    display: block;
    padding: 0;
    margin: 0 0 .5em 0;
  }

  a:link, a:visited {
    text-decoration: none
  }

  a:hover, a:visited:hover {
    text-decoration: underline
  }

  p {
    padding: 3px;
  }

  .script-link {
    margin: 5px;
    float: left;
  }

  .textwidget {
    font-size: smaller;
  }

  .textwidget .avatar {
    border: 0;
  }

  .textwidget ul.scripts {
    clear: both;
  }

  .textwidget ul.scripts li {
    border-bottom: 1px dotted #CBCCD2;
    line-height:14px;
    margin: 0;
  }

  .textwidget ul.scripts li:last-child {
    border-bottom: 0
  }

  .textwidget ul.scripts img.avatar {
    padding: 0;
    margin: 0px 0px 3px 5px;
    width: 14px;
    height: 14px;
    float: right;
  }

  .textwidget ul.scripts li a {
    display: block;
    padding: 3px 0;
  }

  .textwidget ul.scripts.upcoming li .nonlink {
    padding: 3px 0 3px 0px !important;
  }

  .widget_wp_sidebarlogin #loginform, .widget_wp_sidebarlogin .sidebar_login_links {
    padding-top: 20px;
  }

  .widget_wp_sidebarlogin .avatar_container img {
    padding: 0;
  }

}


.widget .dsq-widget-list .dsq-widget-item img {
  width: 14px;
  height: 14px;
  float: right;
  margin: -1px 0 4px 4px;
  border: 0;
  padding: 0;
}

.widget .dsq-widget-list .dsq-widget-item {
  padding-bottom: 2px;
  border-bottom: 1px dotted #CBCCD2;
}

.widget .dsq-widget-list li.dsq-widget-item:last-child {
  padding-bottom: 2px;
  border-bottom: none;
}


#sidebar .script-thumbnails {
  font-size: 125%;
}



.edit-link a {
  font-weight: bold;
  font-size: 140%;
  background-color: $linkColor;
  color: $backgroundColor;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: .5em 0;
  border-radius: 5px;
}

.edit-link a:hover {
  background-color: $linkHoverColor;
  text-decoration: none;
}

.edit-link {
  text-align: center;
  margin: 1em 0;
  display:none;
}


@media screen and (max-width: $totalWidth) {
  #sidebar {
    float: none;
    width: 100%;
    padding: 0 $buffer /2;
    margin-bottom: $buffer /2;
  }
}


@media print {
  #sidebar {
    display: none;
  }
}