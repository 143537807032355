

.threed {
  text-shadow: .2em 0 .2px rgba(0%, 100%, 100%, 0.6), -.2em 0 .2px rgba(100%, 0%, 0%, 0.6) !important;
  color: rgba(40%, 40%, 40%, 0.0);
}

.threed .action {
  text-shadow: .4em 0 .2px rgba(0%, 100%, 100%, 0.6), -.4em 0 .2px rgba(100%, 0%, 0%, 0.6) !important;
}

.threed .character, .threed .paren {
  text-shadow: .6em 0 .2px rgba(0%, 100%, 100%, 0.6), -.6em 0 .2px rgba(100%, 0%, 0%, 0.6) !important;
}

.threed .dialogue {
  text-shadow: .7em 0 .2px rgba(0%, 100%, 100%, 0.6), -.7em 0 .2px rgba(100%, 0%, 0%, 0.6) !important;
}

.threed .scene {
  text-shadow: .8em 0 .2px rgba(0%, 100%, 100%, 0.6), -.8em 0 .2px rgba(100%, 0%, 0%, 0.6) !important;
}


.threed .ssfscript p {
  padding: 0 2em !important;
}

.threed .ssfscript .scene {
  background-color: #eaeaea;
  border-right: .8em solid #eaffff;
  border-left: .8em solid #ffeaea;
  padding: 0 1.5em !important;
}

.abrams .ssfscript {
  color: white !important;
  padding: 1em 1em;
  text-shadow: 0 .1em .1em black, 0 -.1em .1em black, .1em 0 .1em black, -.1em 0 .1em black;
  background-color: black;
  background-image: url("/assets/theme/abramsmode.jpg");
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 5px rgba(0, 0,0, 1);
}
.abrams .ssfscript .scene {
  background-color: transparent !important;
}

.abridged-script .abrams .ssfscript p {
  color: white !important;
}

.invisibly .ssfscript p {
  color: darken($backgroundColor, 2%);

  &::selection {
    color: #51341a;
    background-color: whitesmoke;
  }

  &::-moz-selection {
    color: #51341a;
    background-color: whitesmoke;
  }
}

.noninvisibly .ssfscript p {
  color: #51341a;
}

.argento .ssfscript {
  padding: 1em;
  background-color: #e33;
}

.argento .ssfscript p {
  color: white !important;
}

.argento .ssfscript p.scene {
  background-color: darkred;
}


.silent {
  background-color: #f8f6f7;
  border: 1px solid #EDE8ED;
}


.silent .ssfscript {
  padding: 1em;

}

.silent .scene {
  background-color: #EDE8ED !important;
}


.silent .character { display: none; }

.silent .ssfscript p.dialogue {
  background: #201e1f;
  color: #f8f6f7;
  text-align: center !important ;
  padding: 2em 1em 2em 1em !important ;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-shadow: 0 0 1px #f8f6f7;
  font-family: "Georgia", serif;
  background-attachment:fixed;
  background: #000000 url("/assets/theme/silent_back.png") 0 -50px;
}

.silent .ssfscript p.dialogue:before {
  content:"\"";
}

.silent .ssfscript p.dialogue:after {
  content:"\"";
}


.notice.dnd {
  border-left: 0;
  font-style: normal;
  //font-size: 90%;
  background-color: #fefcf2;
  border-top: 4px solid #a57b27;
  border-bottom: 4px solid #a57b27;
  box-shadow: 0 5px 8px 0 #aaa;

  p.title, h1 {
    margin: 0 !important;
    color: #bc0f0f;
    font-weight: bold;
    text-transform: capitalize;
  }

  hr {
    border-bottom: 2px solid #bc0f0f !important;
    width: 100% !important;
    height: 0 !important;
    margin: 0.5em auto !important;
  }

}

.notice.deco {
  color: $textColor;
  text-align: center !important ;
  padding: 1em;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-family: serif;
  font-size: 100%;
  font-style: normal;

  border: 15px solid transparent; /* control the offset of the lines */
  outline: 2px solid goldenrod; /* adjust the 2px here */
  outline-offset: -10px; /* control the offset of the rectangle */
  background:
          conic-gradient(from 90deg at 2px 2px,#0000 25%,goldenrod 0)
          0 0/calc(100% - 2px) calc(100% - 2px) padding-box border-box;
}