$footerBackgroundColor: $primaryColorTint1;
$footerForegroundColor: $primaryColorTint4;
$footerLinkColor: $primaryColorTint5;
$footerLinkHoverColor: $primaryColorTint4;


#footer-wrapper {
  clear: both;
  background: $footerBackgroundColor;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.4);
  max-width: 100%;
  footer {
    clear: left;
    width: $totalWidth;
    margin: 0 auto;
    color: $footerForegroundColor;
    padding: 30px 0;
    text-align: left;
    font-weight: normal;
    line-height: 1.6em;
    max-width: 100%;


    #infodump p {
      margin: 1em 0;
      font-size: 85%;
      line-height: 1.3em;
    }

    h4 {
      font-family: $headerFont;
      font-size: 1.3em;
      margin-bottom: .5em;
      font-weight: bold;
    }
    .row div {
      margin-bottom: 1em;
    }
    a {
      text-decoration: none;
    }
    a:link,
    a:visited {
      color: $footerLinkColor;
      font-weight: 600;
    }
    a:hover,
    a:active {
      color: $footerLinkHoverColor;
      font-weight: 600;
    }
  }
}

footer {

  #about-us {
    //width: calc(41% - 15px);
    //float: left;
    padding-right: 15px;
    //min-width: 382px;
  }

  #follow-us {
    //width: calc(17% - 30px);
    //float: left;
    padding: 0 15px;
    //min-width: 134px;
  }

  #contact-us {
    //width: calc(17% - 30px);
    //float: left;
    padding: 0 15px;
    //min-width: 134px;
  }

  #credits {
    //width: calc(25% - 15px);
    //float: left;
    //min-width: 227px;
    padding-left: 15px;
  }
}

#infodump {
  display: table;

  #footer-link-collection div{
    display: table-cell;
    white-space: nowrap;
  }

  #about-us{
    display: table-cell;
    width: 100%;
  }

  &::after {
    content:"";
    clear:both;
  }
}

@media screen and (max-width: $totalWidth) {
  #footer-wrapper {
    // min-width: $minWidth;
  }

  footer {
    width: 100%;
  }

  #footer-wrapper {
    footer {
      padding: $buffer/2 0;

      #footer-link-collection {
        display:table;
        width:100%;
      }

      #about-us {
        width: calc(100% - 30px);
        margin: $buffer/2 $buffer/2;
        display:table;
      }

      #follow-us, #contact-us {
        width: calc(33% - 30px);
        margin: $buffer/2 $buffer/2;
      }

      #credits {
        width: calc(33% - 30px);
        margin: $buffer/2 $buffer/2;
      }

    }
  }
}

@media print {
  #footer-wrapper, footer {
    display: none !important;
  }
}