$textColor: $darkColor;

$patreonColor: #f96854;
$linkColor: $primaryColorShade2;

$tagForegroundColor: $primaryColorTint3;
$tagBackgroundColor: $primaryColorShade2;

//Derived Colors
$linkHoverColor: $primaryColorShade3;

$sidebarHeaderColor: $primaryColor;

$avatarBorderColor: $lightColor;

//Units
$scriptThumbnailBorderColor: $lightColor;
$scriptLinkBorderColor: $linkColor;
$scriptLinkHoverBorderColor: $linkHoverColor;
$scriptLinkTitleBackgroundColor: black;
$scriptLinkBackgroundColor: $deepBackgroundColor;
$scriptThumbnailCaptionBackgroundColor: rgba($backgroundColor,0.88);
$scriptThumbnailCaptionForegroundColor: mix($textColor, $backgroundColor, 70%);

$patronIconBackgroundColor: mix($linkColor, $patreonColor);
$quickieIconBackgroundColor: $linkColor;

$bioBackgroundColor: mix($lightColor, $backgroundColor, 20%);
$bioBorderColor: $lightColor;

$tableHeaderBackgroundColor: $bioBackgroundColor;
$tableBorderColor: $bioBorderColor;
$tableOddRowBackgroundColor: mix($backgroundColor, white, 50%);
$tableSortBorderColor: $deepBackgroundColor;

$formElementBackgroundColor: mix($backgroundColor, white, 50%);
$formElementBorderColor: $lightColor;
$formButtonBackgroundColor: $linkColor;
$formButtonBorderColor: $linkColor;
$formButtonHoverBackgroundColor: $linkHoverColor;
$formButtonForegroundColor: $formElementBackgroundColor;

$readingBorderColor: $linkColor;
$readingBackgroundColor: $bioBackgroundColor;

/*

Full screen:
|*[contentWidth][buffer][sidebarWidth]*|

Mobile at <= contentWidth + buffer + sidebarwidth:
|[spacing][*][buffer][sidebarWidth][spacing]|

Mobile at <= contentWidth:
|[spacing][*][spacing]| (content)
|[spacing][*][spacing]| (sidebar)

*/

//Derived Units
$contentWidth: $totalWidth - $buffer - $sidebarWidth;

a {
  font-weight: 600;
}

a:link {
  color: $linkColor;
}

a:visited {
  color: $linkColor;
}

a:active {
  color: $linkHoverColor;
}

a:hover {
  color: $linkHoverColor;
}

@mixin notice-colors($baseColor) {
  border-left: 4px solid $baseColor;
  background-color: mix($baseColor, $backgroundColor, 8%);
}

/* **** Sidebars ****
   ================================================== */

/* **** Outer Container ****
   ================================================== */

#content-wrapper {
  height: 100%;
  width: $totalWidth;
  margin: 0 auto;
  padding-top: $buffer/2;
  text-align: left;
  z-index: 100;
  max-width:100%;
}

#main-content {
  margin-top:15px;

  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

#content-preamble {
  max-width:100%;

  .script-thumbnails {
    margin: $buffer/2 0 $buffer 0;

    figure {
      margin: 0;

      &.full {
        width: 100%;
      }
    }
  }
}

#content {
  float: left;
  width: $contentWidth;
  margin-right: $buffer;
  padding-bottom: $buffer;

  &.script {

  }

  &.post {
    line-height: 1.2em;
  }
}

.abridged-reading {
  align-items: center;
  //border: 1px solid $readingBorderColor;
  //background-color: $readingBackgroundColor;
  @include notice-colors($readingBorderColor);

  &::after {
    display:table;
    content: "";
    clear:both;
  }

  .icon {
    width: 125px;
    height: 125px;
    filter: sepia(80%);
    box-shadow: inset 0px 0px 75px black;
    float: left;
    background-size: cover;
    background-position: center center;
    margin: 0 1em 0 0;
    color: white;
    font-family: serif;
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;

    figcaption.title {
      top: 0;
      padding-top: 5px;
      text-align: center;
      margin: 0 auto;
      font-size: 120%;
    }

    figcaption.subtitle {
      text-align: center;
      position: absolute;
      bottom: 0;
      padding-bottom: 5px;
      width: 125px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
    }

  }

  .audio {
    display: table-cell;

    .description {
      font-family: $bodyFont;
      margin: $buffer/2 0;
      font-weight: 300;
      line-height: 1.3em;
      font-style: italic;
      font-size: .8rem;
    }

    audio {
      width: 100%;
      padding-right:1em;
      padding-bottom:1em;

      &::-webkit-media-controls-enclosure {
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid $readingBorderColor;
      }
    }
  }

}

.author-bio-widget .star-rating {
  text-align: right;
}

.author-bio-widget p {
  font-size: 80%;
  font-weight: 300;
  margin: 0 .2em;
  padding: 0;
}

.author-bio-widget p:first-child {
  margin: 0;
  padding: 0;
}

.author-bio-widget img.avatar {
  border: 1px solid $bioBorderColor;
  float: left;
  margin-right: 5px;
}

.author-bio-widget .sidebar-bio {
  font-size: 80%;
  line-height: 1.3em;
  float:left;
  width: 80px;
  padding-left:1px;
  overflow: hidden;
}

.author-bio-widget .sidebar-bio .name {
  font-size: 120%;
  line-height: 1.3em;
}

.author-bio-widget .sidebar-bio .links {
  font-size: 1.2em;
  border-top: 1px solid $bioBorderColor;
  padding-top: 5px;
  margin-top: 5px;
  display: block;
}

/* **** Main Content Column ****
   ================================================== */

//#contentwrapper {
//  float: right;
//  width: 100%;
//  padding-bottom: 30px;
//}
//
//#content {
//  margin: 0 ($sidebarWidth+$buffer) 0 0px; /*Margins for content column. Should be "0 RightColumnWidth 0 LeftColumnWidth*/
//  line-height: 1.3em;
//  max-width: $contentWidth;
//}

#content ul {
  padding-left: 15px
}

#content ul li {
  margin-left: 15px;
  list-style-type: square;
}

#content hr {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
  width: 96%;
  margin: auto;
}

#content .post ol {
  padding-left: 1em;
  margin-left: 1em;
}

#content h1 {
  font-size: 120%;
  font-weight: bold;
}

#content h1, #content .post h2, #content .post h3 {
  margin: 20px 0 10px 0;
  font-weight: bold;
}

#content h2 {
  font-size: 115%;
  font-weight: bold;
}

#content .post ol li {
  margin-left: 1em;
  list-style-type: decimal;
  list-style-position: outside;
}

#content.post p {
  text-align: justify;
  margin-bottom: 12px;
  margin-top: 12px;
}

#content br {
  height: 0px;
  margin: 0;
}

#content h4 {
  border: 1px solid #9f9f9f;
  border-left: 0px;
  border-right: 0px;
  background-color: #efefef;
  padding: .3em 1em .3em 1em;
  margin: .5em 0;
}

#content h2 {
  margin: 0 0 .5em 0
}

#content ul {
  margin-bottom: 10px
}

#post-footer {
  margin-top: 1em;
}

.abridged-script #post-footer p {
  font-family: $bodyFont;
  margin: 0;
}

a.patron-only {
  color: mix($linkColor, $patreonColor);
}

#content h3.posttitle {
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 1.5em;
  text-decoration: none;
  font-family: $headerFont;
}

#content h3.posttitle a {
  text-decoration: none;
  border-bottom: 1px solid;
}

.notice {
  font-family: $bodyFont;
  margin: $buffer/2 0;
  padding: 1em;
  font-weight: 300;
  line-height: 1.3em;
  @include notice-colors($navElementColor);
  font-style: italic;
  font-size: .8rem;
}

.notice:first-child {
  margin-top: 0;
}

.notice:last-child {
  margin-bottom: 0;
}

.notice p, #content .notice p {
  font-size: 1em;
  margin: 0.5em 0;
}

.notice a {
  font-weight: bold;
}

.notice p:first-child, #content .notice p:first-child {
  margin-top: 0;
}

.notice p:last-child, #content .notice p:last-child {
  margin-bottom: 0;
}

.notice.patreon {
  @include notice-colors($patreonColor);
}

.notice.contribution {
  @include notice-colors(#525464);
}

.notice.error {
  @include notice-colors(#e6281a);
}

.notice.news {
  @include notice-colors(#93829E);
}

.notice h2 {
  margin: 10px 0 !important
}

#content.abridged-script .entry .notice {
  font-size: 90%;
}

#content .entry {
  font-weight: 400;

  &.entrytext {
    line-height: 1.6;
  }

  &.entrytext a {
    font-weight: bold;
  }
}

#content.abridged-script .entry {
  font-size: 1.1em;
}

#content .post {
  display: block;
  /*padding: 0 13px 0 13px;*/
  padding: 0;

}

.dim {
  color: mix($backgroundColor, $textColor, 50%);
}
.halves {
  padding: 0 .2ch;
}

.patron-denied {
  background: repeating-linear-gradient(
                  30deg,
                  $backgroundColor,
                  $backgroundColor 20px,
                  mix($backgroundColor, $patreonColor, 96%) 20px,
                  mix($backgroundColor, $patreonColor, 96%) 40px
  );
}

#content #patron-only-error {
  border-top: 3px solid $patreonColor;
  margin-top: 1.5em;
  padding-top: 1.5em;
  font-size: 1rem;
}

#content #patron-only-error p{
  font-family: $bodyFont;
  margin-bottom: 1em;
}

#content #patron-only-error .denied {
  text-align: center;
}

#content #patron-only-error .denied p {
  text-align: center;
  font-size: 120%;
}

#content.script {
  font-family: $scriptFont;
}

.ssfscript {
  color: $textColor;
  text-align: left;
  font-family: $scriptFont;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.15em;
  -webkit-text-size-adjust: none;

  .title {

    a, a:link, a:visited, a:active, a:hover {
      color: $textColor;
      text-decoration: none;
    }

    .avatar {
      height: 1em;
      width: 1em;
      display: none;
    }

    p.action {
      text-align: center;
    }

    p.movietitle {
      padding: 0 2em;
      font-weight: bold;
      font-size: 125%;
      letter-spacing: 0.1em;
      line-height: 1.3em;
      text-decoration: underline;
      margin-top: 0;
    }

    .byline {
      padding: 0 2em;
    }

    .title-footer {
      margin: 1em 0 2em 0;

      .rating {
        float: left;
      }
      .date {
        float: right;
      }

      .credits {
        display: none;
      }

      &::after {
        content: "";
        display: table;
        clear:both;
      }
    }
  }

  p {
    word-wrap: break-word;
    text-align: left;
    /*white-space: pre-wrap; */
    padding: 0;

    sub, super {
      font-size: 0.6em;
    }
    
    big {
      font-size: 3em;
      line-height: .9em;
    }

    &.center {
      text-align: center;
    }

    &.scene {
      white-space: normal;
      background-color: mix($textColor, $backgroundColor, 10%);
      font-weight: bold;
      line-height: 1.2em;
      padding: 0.25em 0.5em 0.25em calc(5ch + 0.5em);
      margin: 1em 0 1em -0.5em;
      text-indent: -5ch;
    }

    &.action {
      margin-top: 1em
    }

    &.comment {
      margin-top: 1em;
      color: red;
    }

    &.character {
      text-align: center;
      margin: 0em 18ch;
      margin-top: 1em;
    }

    &.paren {
      margin: 0em 17ch 0 18ch;
      font-style: italic;
      text-indent: -1ch;
    }

    &.dialogue {
      margin: 0em 13ch;
    }

    &.transition {
      text-align: right;
      margin-top: 1em;
    }

    &.fade {
      text-align: left;
      font-weight: bold;
      display: block;
      visibility: visible;
      opacity: 100;
    }

    &.transition.fade:first-child {
      margin-top: 2em;
    }

  }

}

#tag-cloud {
  margin-top: $spacing;
}

.tag {
  display: inline-block;
  margin: 0 .5ch .5ex 0;
  padding: .5ex .5ch;
}

a.tag {
  font-weight: bold;
}

.tag.has-tag-page {
  background-color: $tagBackgroundColor;
  color: $tagForegroundColor;
  border-radius: 2px;
  text-decoration: none;
}


.tag-count {
  display: none;
}

#tagSelect {
  font-size: 120%;
  margin: 1em 0;
  padding: .2em;
  font-weight: bold;
  border: 1px solid $primaryColorTint4;
  font-family: $bodyFont;
}

.right {
  float: right;
  margin: 0;
  margin-left: .7em;
  margin-bottom: .7em;
  padding: .7em;
  padding-bottom: 0;
}

.left {
  float: left;
  margin: 0;
  margin-right: .7em;
  margin-bottom: .7em;
  padding: .7em;
  padding-bottom: 0;
}

.photo img {
  border: 1px solid $avatarBorderColor;
  max-width: 300px;
  max-height: 200px;
}

.photo .caption {
  padding: 4px;
  font-size: 85%;
}

.photo a img {
  border: 1px dashed $avatarBorderColor;
  padding: 0px;
}


#search {
  float: right;
  margin-top: 8px;
}

.listicon.category-wrapper {
  display: inline-block;
  background-size: cover;
  width: 9px;
  height: 9px;
  margin: 0 12px -1px 2px;
  background-color: black;

  &.vas {
    background-image: url(theme/quickie.png);
  }
  &.patron {
    background-image: url(theme/patreon.png);
  }
}

a .listicon.category-wrapper {
  &.vas {
    background-color: $scriptLinkBorderColor;
  }
  &.patron {
    background-color: $patronIconBackgroundColor;
  }
}

a:hover .listicon.category-wrapper {
  &.vas {
    background-color: $scriptLinkHoverBorderColor;
  }
  &.patron {
    background-color: $patronIconBackgroundColor;
  }
}

table.archivetable {
  font-size: smaller;

  .author, .date, .number {
    text-align: right;
  }

  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

table.archivetable tr td a {
  border-bottom: 0;
  text-decoration: none;

  &:hover {
    text-decoration:underline;
  }
}

table.archivetable .abridgedscriptTitle {
  border-bottom: 0px;
  font-weight: normal;
}

table.archivetable {
  width: 100%;
  margin-bottom: 2em;
  border-collapse: collapse;
}

table.archivetable th {
  padding: 5px 0px;
  font-weight: bold;
}

table.archivetable td {
  padding: .3em;
  vertical-align: middle;
}

table.archivetable tr.odd {
  background-color: $tableOddRowBackgroundColor;
}


table.archivetable tr:nth-child(2n) td {
  background-color: $tableOddRowBackgroundColor;
}

table.archivetable td.commentcolumn {
  text-align: center;
}

table.archivetable td.titlecolumn {
  width: 70%
}

table.archivetable td.ratingcolumn {
  width: 10%
}

table.archivetable td.datecolumn {
  text-align: right;
  width: 20%;
}

table.archivetable td.rating {
  text-align:center;
}

table.archivetable th {
  padding: 5px 0px;
}

table.archivetable td {
  border-bottom: 1px dotted $tableBorderColor;
}

table.archivetable thead th {
  background-color: $tableHeaderBackgroundColor;
  padding: .4em;
  font-weight: normal;
  border-bottom: 1px solid $backgroundColor;
  border-top: 1px solid $backgroundColor;
}

table.archivetable.sortable thead th {
  cursor: pointer !important
}

table.archivetable td.author {
  text-align: right
}

th.sorttable_sorted, th.sorttable_sorted_reverse, th.sorttable_presorted {
  border-bottom: 2px solid #B9BAC0 !important
}

.category_name {
  font-weight: bolder;
  margin: 1em 0 .7em 0;
  font-size: 120%;
}

table.archivetable th {
  padding: 5px 0px;
}

table.archivetable th img {

}

table.archivetable img.avatar {
  padding: 0;
  margin: 0 0 -2px 0;
  border: 0;

}


th[role=columnheader]:not(.no-sort) {
  cursor: pointer;
}

th[aria-sort=descending]:not(.no-sort) {
  border-top: 1px solid $tableSortBorderColor !important;
  border-bottom: 1px solid $backgroundColor !important;
}

th[aria-sort=ascending]:not(.no-sort) {
  border-top: 1px solid $backgroundColor !important;
  border-bottom: 1px solid $tableSortBorderColor !important;
}


.author_bio {
  min-height: 60px;
  margin: 1em 0;
  border: 1px solid $bioBorderColor;
  padding: 0;

  &:after {
    content:"";
    clear:both;
    display: table-cell;
  }
}

.author_bio .avatar {
  padding: 0;
  border: 0;
  border-right: 1px solid $bioBorderColor;
  border-bottom: 1px solid $bioBorderColor;
  margin: 0;
  margin-left: 0;
  margin-right: 10px;
  float: left;
}

.author_bio .info {
  padding: 0
}

.author_bio .name {
  font-size: 18px;
  padding: 5px;
  height: 48px;
  border-bottom: 1px solid $bioBorderColor;
  background-color: $bioBackgroundColor;

  &:after {
    content:"";
    clear:both;
    display: table-cell;
  }
}

.widget-author-bio {

  .name {
    font-weight: normal;
  }

  .author_bio {
    margin: 0 0 10px 0;
  }

  .star-rating {
    font-size: 300%;
    text-align: center;
    margin: 10px;
  }
}

.author_bio .name .link {
  display: block;
  font-size: 14px;
  margin: 7px;
  line-height: 14px;
}

.author_bio .name .contact_link {
  display: block;
  font-size: 14px;
  margin: 5px;
  line-height: 14px;
  float: right;
  height: 38px;
  margin-top: -38px;
}

.author_bio .description {
  padding: 0.5em
}


.star-rating {
  font-size: 28px;
  margin: 0 0 1em 0;
  color: #73758F;
  letter-spacing: 4px;
}

.gimmick_notice {
  border: 1px dotted #888;
  background-color: #ddd;
  margin: 1em 0;
  padding: 1em;
  text-align: center;
  font-family: $bodyFont;
  color: #111;
}

.patreon.gimmick_notice {
  background-color: #ffab81;
}

.sidebar .script-link {
  margin: 10px 0;
}

.script-thumb {
  border-color: $scriptThumbnailBorderColor;
  border-style: solid;
  position:relative;
  max-width: 100%;
}
.thumbnail-caption {
  background-color: $scriptThumbnailCaptionBackgroundColor;
  border-top: 1px solid $scriptThumbnailBorderColor;
  position: absolute;
  /*border-top: 0;*/
  color: $scriptThumbnailCaptionForegroundColor;
  font-size: 12px;
  line-height: 1em;
  font-weight: 400;
  text-align: center;
  bottom: 0;
}

.clearboth {
  clear: both;
}

.script-thumbnails {
  margin: -5px -5px -5px -5px;
  font-size: 200%;


  &:after, &:before {
    content: "";
    display: table;
    clear: both;
  }

  figure {
    border: 1px solid $lightColor;
    display: inline-block;
    position: relative;
    float: left;
    margin: 5px;
    &.full {
      width: calc(100% - 10px);
      font-size: 100%;
    }
    &.half {
      width: calc(50% - 10px);
      font-size: 70%;
    }
    &.quarter {
      width: calc(25% - 10px);
      font-size: 45%;
    }
    .inner-thumbnail {
      background-size: cover;
      background-position: center center;

      @include aspect-ratio(16,9)
    }
    .avatar-wrapper {
      position: absolute;
      width: 6%;
      display: block;
      border: 1px solid $avatarBorderColor;
      border-top: 0;
      border-left: 0;
      background-size: cover;
      min-width: 16px;
      max-width: 64px;

      &::after {
        content: "";
        display: table;
        padding-bottom: 100%;
      }
    }
    .category-wrapper {
      position: absolute;
      width: 6%;
      display: block;
      border: 1px solid $avatarBorderColor;
      background-color: $avatarBorderColor;
      border-top: 0;
      border-right: 0;
      background-size: cover;
      min-width: 16px;
      max-width: 64px;
      right: 0px;

      &.vas {
        background-image: url(theme/quickie.png);
      }
      &.patron {
        background-image: url(theme/patreon.png);
      }

      &::after {
        content: "";
        display: table;
        padding-bottom: 100%;
      }
    }
    figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      &.title {
        background: linear-gradient(rgba($linkColor, 0.0) 0%, rgba($linkColor, 1) 100%);
        color: white;
        line-height: 1.2em;
        padding: 0.5em 1em;
        font-weight: 600;
        text-shadow: rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px -1px 0px, rgb(0, 0, 0) -1px 1px 0px, rgb(0, 0, 0) 1px 1px 0px;
      }
      &.caption {
        background: $scriptThumbnailCaptionBackgroundColor;
        color: $scriptThumbnailCaptionForegroundColor;
        border-top: 1px solid $lightColor;
        line-height: 1.3em;
        font-size: 45%;
        padding: 0.7em 1em;
        font-weight: 400;
      }
    }
  }
  a figure {
    border: 1px solid $linkColor;
    .inner-thumbnail {
      -webkit-filter: grayscale(10%);
      -moz-filter: grayscale(10%);
      -ms-filter: grayscale(10%);
      -o-filter: grayscale(10%);
      opacity: 0.95;
    }
    figcaption.title {
      background: linear-gradient(rgba($linkColor, 0.0) 0%, rgba($linkColor, 1) 100%);
      //color: #eeeeee;
      text-shadow: $linkColor -1px -1px 0px, $linkColor 1px -1px 0px, $linkColor -1px 1px 0px, $linkColor 1px 1px 0px;
    }
    .avatar-wrapper, .category-wrapper {
      border-color: $linkColor;
      background-color: $linkColor;
    }
  }

  a:hover figure {
    border: 1px solid $linkHoverColor;
    .inner-thumbnail {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
      opacity: 1.0;
    }
    .inner-duotone {
      background-image: none !important;
    }
    .avatar-wrapper, .category-wrapper {
      border-color: $linkHoverColor;
      background-color: $linkHoverColor;
    }
    figcaption.title {
      background: linear-gradient(rgba($linkHoverColor, 0.0) 0%, rgba($linkHoverColor, 1) 100%);
      //color: #eeeeee;
      text-shadow: $linkHoverColor -1px -1px 0px, $linkHoverColor 1px -1px 0px, $linkHoverColor -1px 1px 0px, $linkHoverColor 1px 1px 0px;
    }
  }

  a.patron-only figure {
    border: 1px solid mix($linkColor, $patreonColor);
    .inner-thumbnail {
      -webkit-filter: grayscale(10%);
      -moz-filter: grayscale(10%);
      -ms-filter: grayscale(10%);
      -o-filter: grayscale(10%);
      opacity: 0.95;
    }
    figcaption.title {
      background: linear-gradient(rgba(mix($linkColor, $patreonColor), 0.0) 0%, rgba(mix($linkColor, $patreonColor), 1) 100%);
      //color: #eeeeee;
      text-shadow: mix($linkColor, $patreonColor) -1px -1px 0px, mix($linkColor, $patreonColor) 1px -1px 0px, mix($linkColor, $patreonColor) -1px 1px 0px, mix($linkColor, $patreonColor) 1px 1px 0px;
    }
    .avatar-wrapper, .category-wrapper {
      border-color: mix($linkColor, $patreonColor);
      background-color: mix($linkColor, $patreonColor);
    }
  }

  a.patron-only:hover figure {
    border: 1px solid mix($linkHoverColor, $patreonColor);
    .inner-thumbnail {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
      opacity: 1.0;
    }
    .inner-duotone {
      background-image: none !important;
    }
    .avatar-wrapper, .category-wrapper {
      border-color: mix($linkHoverColor, $patreonColor);
      background-color: mix($linkHoverColor, $patreonColor);
    }
    figcaption.title {
      background: linear-gradient(rgba(mix($linkHoverColor, $patreonColor), 0.0) 0%, rgba(mix($linkHoverColor, $patreonColor), 1) 100%);
      //color: #eeeeee;
      text-shadow: mix($linkHoverColor, $patreonColor) -1px -1px 0px, mix($linkHoverColor, $patreonColor) 1px -1px 0px, mix($linkHoverColor, $patreonColor) -1px 1px 0px, mix($linkHoverColor, $patreonColor) 1px 1px 0px;
    }
  }
}


/* ===[ Contact Form Stuff ]=== */

fieldset {
  display: block;
  margin: 2em 3px;
  padding: 1em 1.5em .5em 1.5em;
  border: 1px solid #888;
}

legend {
  font-size: 1.5em;
  margin: 0 10px 0 -10px;
  display: block;
  padding: 0 5px;
}

/* Form Styling */

input[type=text], input[type=email], input[type=number], textarea {
  padding: 0.5em;
  box-sizing: border-box;
  background-color: $formElementBackgroundColor;
  border: 1px solid $formElementBorderColor;
}

input[type=button], button, input[type=submit], .button {
  padding: 0.5em;
  box-sizing: border-box;
  background-color: $formButtonBackgroundColor;
  border: 1px solid $formButtonBorderColor;
  color: $formButtonForegroundColor;
  border-radius: 3px;
}

input[type=button]:hover, button:hover, input[type=submit]:hover, .button {
  background-color: $formButtonHoverBackgroundColor;
  cursor: pointer;
}

//New one
form {
  label {
    display:block;
    width: 100%;
    padding: 0;
  }

  input[type=text], textarea, input[type=number], input[type=email] {
    width: 100%;
    padding: 0.5em;
    margin: 10px 0 20px 0;
    box-sizing: border-box;
  }

  textarea {
    height: 300px;
  }

  input[type=submit] {
    padding: 0.5em;
    margin: 10px 0 20px 0;
    font-size: 1.2em;
  }
}

form.inline {
  label {
    float: left;
    width: 20%;
    display: inline-block;
    padding: 0.5em 0;
    margin: 10px 0 10px 0;
  }

  input[type=text], textarea, input[type=number], input[type=email] {
    float: left;
    width: 79%;
    display: inline-block;
    padding: 0.5em;
    margin: 10px 0 10px 0;
  }

  &::after {
    content: "";
    clear: both;
  }
}


/* ===[ Responsive Mods ]=== */
@media screen and (max-width: $totalWidth) {
  .desktop-only {
    display:none !important;
  }

  .mobile-only {
    display: block;
  }

  body {

  }

  #content {
    float: none;
    width: 100%;
    padding: 0 $buffer/2;
    margin: 0 auto;
  }

  #content.script {
    max-width: 640px;
  }

  #navigation-wrapper {
    // min-width: $minWidth;
  }

  #navigation-wrapper #navigation {
    width: 100%;
    // min-width: $minWidth;
  }

  #navigation .menu-icon {
    display: none;
  }

  #navigation li a {
    font-size: 0.7em;
  }

  #rightnav {
    width: 100%;
    padding: 0 10px 10px 10px;

    .script-link {
      float: left;
      margin: 5px 5px;
    }
  }

  #rightnav .sidebar.sidebarleft {
    background-image: none;
    background-color: #efefef;
  }

  #rightnav .sidebar.sidebarleft h2 {
    background-image: none
  }

  #rightnav .sidebar.sidebarleft .avatar {
    display: none
  }

  #discussion-wrapper {
    // min-width: $minWidth;
  }

  #discussion-wrapper #discussion {
    width: 100%;
    // min-width: $minWidth;
  }

  #outercontainer {
    width: 100%;
    margin: 30px auto 0 auto;
    // min-width: $minWidth;
  }

  #content {
    margin: 0 auto;
  }

  .script-thumb {
    max-width: 100%;
    margin-left:-1px;
    overflow: hidden;
  }

  #discussion .adsbygoogle {
    display: none !important;
  }

  #content .recent_post.full .script-preview {
    display: none !important;
    visibility: hidden;
  }

  #discussion {
    max-width: 100%;
    overflow: hidden;
    padding: 0 10px;
  }

  .widget_good {
    // display: none;
  }
}

@media screen and (max-width: $contentWidth) {
  #content {
    padding-left: 10px;
    padding-right: 10px;

    .mobile-only {
      display: block;
    }
  }

  #content.abridged-script .entry
  {
    font-size: 95%;
  }

  #content .ssfscript
  {
    font-size: 100%;
    line-height: 1.1em;
    margin: 0 1.5ch;
  }
  #content .ssfscript .title
  {
    margin: 3em 1em 4em 1em;
  }
  #content .ssfscript .title .movietitle
  {
    letter-spacing: .2em;
  }
  #content .ssfscript p
  {
    //padding: 0 0.5em;
    word-wrap: break-word;
  }
  #content .ssfscript p.scene
  {
    //padding: .3em .5em .05em .5em;
    margin: 1em 0em 0em 0em;
  }
  #content .ssfscript p.dialogue
  {
    margin: 0em 20%;
  }
  #content .ssfscript p.paren
  {
    margin: 0em 25%;
  }
  #content .ssfscript p.character
  {
    margin: 0em 26%;
    margin-top: 1em;
  }
  #content .ssfscript p.transition
  {
    margin-top: 1em;
  }

}

@media screen and (max-width: 550px) {
  .script-thumb {
    max-height: 300px;
  }

}

@media screen and (max-width: 480px) {
  #content.abridged-script .entry
  {
    font-size: 90%;
  }

}

@media screen and (max-width: 430px) {
  #navigation-wrapper #navigation ul li a {
    font-size: 70%;
  }

  .script-thumb {
    max-height: 250px;
  }

}

@media screen and (max-width: 360px) {
  #navigation-wrapper #navigation ul li a {
    font-size: 60%;
  }

  #content .entry
  {
    font-size: 80%;
    line-height: 1.1em;
  }

  #content.abridged-script .entry
  {
    font-size: 80%;
    line-height: 1.1em;
  }

}

@media print {
  #content-preamble {
    display: none;
  }

  #post-footer, .notice, .screenonly {
    display: none;
  }

  #content {
    color: black !important;
    margin: 0 auto;
    width:100%;
    padding: 0;
    float: none;
  }

  .ssfscript{
    text-align:left;
    font-size:1.25em;
  }
  .ssfscript p{
    font-style:normal;
  }
  .ssfscript p.center{
  }
  .ssfscript p.action{
  }
  .ssfscript p.scene{
    margin-top: 2em;
    background-color: #ffffff;
    page-break-after:avoid;
  }
  .ssfscript p.scene:first-child{
    margin-top: 1em;
  }
  .ssfscript p.dialogue{
    page-break-before:avoid;
  }
  .ssfscript p.paren{
    page-break-before:avoid;
    page-break-after:avoid;
  }
  .ssfscript p.character{
    page-break-after:avoid !important;
  }
  .ssfscript p.transition{
    page-break-after:avoid;
  }
  .ssfscript p.fade{
    page-break-after:avoid;
    page-break-before:avoid
  }

  .ssfscript .title {
    margin-top: 3in;
    page-break-after: always;
  }

  .ssfscript .title .title-footer {
    padding-top: 3in;

    p.action {
      float: none;
      text-align: right;
      margin: 0;
    }

    .credits {
      margin-top:1em;
      display: block;
    }
  }

}

@import "_content.gimmicks";
@import "_content.sidebar";


