/* **** Style Reset *****
   ================================================== */
//@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=auto');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');


//Fonts
$bodyFont: 'Open Sans', sans-serif;
$headerFont: 'Open Sans Condensed', sans-serif;
$scriptFont: 'Courier Prime', 'Courier', 'Courier New', monospace;

//https://colordesigner.io/?presentationMode=true#6D7095-D9CB9E-374140-2A2C2B-BDC3C7
$primaryColor:#6D7095;
$primaryColorTint1: #8a8daa;
$primaryColorTint2: #a7a9bf;
$primaryColorTint3: #c5c6d5;
$primaryColorTint4: #e2e2ea;
$primaryColorTint5: #ffffff;
$primaryColorShade1: #5d6080;
$primaryColorShade2: #4e506b;
$primaryColorShade3: #3e4055;
$primaryColorShade4: #2f3040;
$primaryColorShade5: #1f202b;
$primaryColorShade6: #101015;
$primaryColorShade7: #000000;
$highlightColor: $primaryColorTint3;
$lowlightColor: $primaryColorShade3;
$darkColor: $primaryColorShade4;
$lightColor: $primaryColorTint3;

//Main Colors
$navElementColor: $primaryColor;
$backgroundColor: $primaryColorTint5;
$deepBackgroundColor: $primaryColorShade1;
$lightColor: $primaryColorTint3;

$outlineColor: #A68F4F;

$totalWidth: 970px;
$sidebarWidth: 300px;
$buffer: 30px;
$headerHeight: 150px;
$spacing: 5px;

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:after {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
        height:0;
    }
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

/* remember to define focus styles!  */

a:focus {
    outline: auto 2px $outlineColor !important; // for non-webkit browsers
    outline: auto 5px $outlineColor !important; // for webkit browsers
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none
}

/* tables still need 'cellspacing="0"' in the markup  */

table {
    border-collapse: separate;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
    font-weight: normal;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: ""
}

blockquote, q {
    quotes: "" ""
}

/* **** Basic Page Styles ****
   ================================================== */

body {
    font-size: 13px;
    margin: 0px;
    padding: 0px;
    background-color: $backgroundColor;
}

html body {
    font-family: $bodyFont;
}

a img {
    border: none;
    background: none;
    padding: 5px;
}

img {
    border: none
}

blockquote {
    margin: 0px 0px 0px 30px;
    padding: 0px 25px 0px 0px;
}

strong {
    font-weight: bold
}

strike {
    text-decoration: line-through
}

.desktop-only {
    display:block;
}

.mobile-only {
    display: none;
}

.totally-not-something-to-block {
    margin: 0 auto;
    text-align: center;
}

#word-count {
    position: fixed;
    bottom: 0px;
    background-color: white;
    width: 100%;
    left: 0px;
    right: 0px;
    padding: .5em;
    border-top: 1px solid #ddd;
}

@page
{
    size: auto;
    margin: 1in;
}

@import "_masthead";
@import "_top-navigation";
@import "_content";
@import "_discussion";
@import "_footer";
