$navbarBackgroundColor: $primaryColorShade2;
$navbarBorderColor: $primaryColorTint3;
$navbarForegroundColor: $navbarBorderColor;

$navbarHoverBackgroundColor: $primaryColorShade3;
$navbarHoverForegroundColor: $navbarForegroundColor;

#navigation-wrapper {
  // background: rgba($navElementColor,1) url("/wp-content/themes/hollywood-sucks.v4/img/film-strip.png");
  // background-size: 137px 26px;
  // background-position: center;
  background-color: $navbarBackgroundColor;
  //background: -moz-linear-gradient(top,  rgba($navElementColor,1) 0%, rgba($navElementLowlight,1) 90%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(top,  rgba($navElementColor,1) 0%,rgba($navElementLowlight,1) 90%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(to bottom,  rgba($navElementColor,1) 0%,rgba($navElementLowlight,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient(  startColorstr='#{ie-hex-str($navElementColor)}', endColorstr='#{ie-hex-str($navElementLowlight)}',GradientType=0 ); /* IE6-9 */

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow:    0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow:         0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  z-index: 100;

  nav {
    display: table;
    table-layout: fixed;
    width: $totalWidth;
    margin: 0 auto;
    border-left: 1px solid $navbarBorderColor;

    background-color: $navbarBackgroundColor;
    //background: -moz-linear-gradient(top,  rgba($navElementColor,0.7) 0%, rgba($navElementLowlight,0.7) 90%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top,  rgba($navElementColor,0.7) 0%,rgba($navElementLowlight,0.7) 90%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom,  rgba($navElementColor,0.7) 0%,rgba($navElementLowlight,0.7) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(  startColorstr='#{ie-hex-str(rgba($navElementColor, 0.7))}', endColorstr='#{ie-hex-str(rgba($navElementLowlight,0.7))}',GradientType=0 ); /* IE6-9 */

    .home-logo {
      display: none;
    }

    .hamburger-menu {
      display: none;
    }

    ul {
      display: table-row;
      margin: 0;
      padding: 0;
    }

    ul li {
      list-style: none;
      display: table-cell;
      text-align: center;
      border-right: 1px solid;
      border-color: $navbarBorderColor;
      vertical-align: middle;
    }

    ul li a {
      display: block;
      padding: 8px 3px;
      font-size: 0.8em;
      font-weight: 700;
      line-height: 1.1em;
      overflow: hidden;
      text-decoration: none;
      color: $navbarForegroundColor;
    }

    ul li a:link, nav ul li a:visited {
      color: $navbarForegroundColor;
    }

    ul li:not(.home-logo):hover {
      background-color: $navbarHoverBackgroundColor;
    }

    ul li:hover a {
      color: $navbarHoverForegroundColor;
    }
  }
}

@media print {
  #navigation-wrapper {
    display: none;
  }
}

@media screen and (max-width: $totalWidth) {
  #navigation-wrapper {
    border-top: 1px solid $navbarBorderColor;

    nav {
      border: 0;
      width: 100%;

      .home-text {
        display: none;
      }
    }
  }
}
